import React from 'react';
import { useSnackbar as useSnack, VariantType } from 'notistack';
import { SnackbarContent } from 'notistack';
import styles from './CustomSnackbar.module.scss';

export enum SnackbarTypes {
  Info = 'info',
  Error = 'error',
  Success = 'success',
  Warning = 'warning',
  Default = 'default',
}

type SnackbarType = 'info' | 'error' | 'success' | 'warning' | 'default';

const useSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnack();

  return {
    openSnackbar: (
      message = 'Sorry, something went wrong!', type: SnackbarType = SnackbarTypes.Error ) =>
      enqueueSnackbar(message, {
        variant: type as VariantType,
        autoHideDuration: 3000,
      }),
    openCustomSnackbar: (message = 'Deleted', button = 'Undo', onClick: () => void) =>
      enqueueSnackbar(message, {
        autoHideDuration: 5000,
        content: () => (
          <SnackbarContent className={ `SnackbarItem-contentRoot ${ styles.customSnackbar }` }>
            <div className={ `SnackbarItem-message ${ styles.content }` }>
              { message }
              <button onClick={ onClick } className={ styles.button }>
                { button }
              </button>
            </div>
          </SnackbarContent>
        )
      }),
    closeSnackbar,
  };
};

export default useSnackbar;