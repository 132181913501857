import { PaginateParams } from 'data/interfaces/shared';
import endpoints from '../data/endpoints';
import { destroy, get, post, patch } from '../lib/axios';

export const CompanyService = {
  index: (params?: PaginateParams) => get(endpoints.companies.index, { params }),
  single: (id: number) => get(endpoints.companies.single(id)),
  create: (data: FormData) => post(endpoints.companies.index, data),
  update: (id: number, data: FormData) =>
    patch(endpoints.companies.single(id), data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
  destroy: (id: number) => destroy(endpoints.companies.single(id)),
  syndications: () => get(endpoints.companies.syndications),
};
