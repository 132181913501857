import React from 'react';
import { Company } from '../data/interfaces/company';
import { User } from '../data/interfaces/user';

export type UserContextType = {
  user: User | null;
  company: Company | null;
  setUser: (user: User) => void;
  setCompany: (company: Company | null) => void;
};

export const UserContext = React.createContext({
  user: null,
  company: null,
  setUser: () => null,
  setCompany: () => null,
} as UserContextType);
export default UserContext;
