/* eslint-disable @typescript-eslint/no-explicit-any */
import useSnackbar from 'components/ui/Snackbar/useSnackbar';
import { UseFormSetError } from 'react-hook-form';

const useHandleErrors = (
  setError?: UseFormSetError<any>, keysWithoutSnackbar?: string[]
) => {
  const { openSnackbar } = useSnackbar();

  const setErrorMessage = (key: string, message: any) => {
    let error: string;
    if (typeof message === 'object' && !Array.isArray(message)) {
      error = Object.values(message).join(', ').toString();
    } else {
      error = message.toString();
    }
    setError && setError(key as any, { type: 'custom', message: error as any });

    const indexOfFirstNumber = key.search(/.[0-9]/);
    const parsedKey = indexOfFirstNumber ? key.substring(0, key.search(/.[0-9]/)) : key;
    !keysWithoutSnackbar?.includes(parsedKey)
     && openSnackbar(`${ parsedKey }: ${ error }`);
  };

  const handleErrors = (error: any, defaultErrorMessage?: string) => {
    const openDefaultError = () =>
      openSnackbar(
        defaultErrorMessage ||
        error.message ||
        error.response?.statusText ||
        'Oops! Something went wrong!'
      );

    if (error.response?.data && typeof error.response.data === 'object') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { nonFieldErrors, ...fieldErrors } = error.response.data;

      const handleValue = (key: string | null, value: {} | [] | string) => {
        if (typeof value === 'object' && !Array.isArray(value)) {
          for(const item of Object.entries(value)) {
            handleValue(`${ key ? key+'.' : '' }${ item[ 0 ] }`, item[ 1 ]);
          }
        } else if (typeof value === 'object' && Array.isArray(value) && value.length) {
          value.forEach((item, index: number) => {
            typeof item === 'string'
              ? setErrorMessage(key || '', item)
              : handleValue(`${ key ? key+'.' : '' }${ index }`, item);
          });
        } else {
          setErrorMessage(key || '', value);
        }
      };

      if (fieldErrors && typeof fieldErrors === 'object' && Object.entries(fieldErrors).length) {
        handleValue(null, fieldErrors);
      } else if (nonFieldErrors?.length) {
        nonFieldErrors.forEach((message: string) => {
          openSnackbar(message);
        });
      } else {
        openDefaultError();
      }
    } else {
      openDefaultError();
    }
  };

  return {
    handleErrors,
  };
};

export default useHandleErrors;