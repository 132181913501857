import React, { lazy, Suspense, useState } from 'react';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material';
import {
  Route,
  Routes,
  unstable_HistoryRouter as HistoryRouter
} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import UserContext, { UserContextType } from './context/user.context';
import { Company } from './data/interfaces/company';
import { User } from './data/interfaces/user';
import AuthGuard from './guards/AuthGuard';
import './styles/global.scss';
import { history } from './utils/history';

const AppRoutes = lazy(() => import('./routes/AppRoutes'));
const AuthRoutes = lazy(() => import('./routes/AuthRoutes'));

const theme = createTheme({
  typography: {
    fontFamily: 'museo-sans, sans-serif;',
  },
  palette: {
    primary: {
      main: '#8868c7',
    },
  },
});

const App = () => {
  const [ userCtx, setUserCtx ] = useState<UserContextType>({
    user: null,
    company: null,
    setUser: (data: User) => setUserCtx((state: UserContextType) => ({ ...state, user: data })),
    setCompany: (data: Company | null) =>
      setUserCtx((state: UserContextType) => ({ ...state, company: data })),
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={ theme }>
        <UserContext.Provider value={ userCtx }>
          <Suspense fallback={ <></> }>
            <SnackbarProvider maxSnack={ 5 }>
              <link rel='stylesheet' href='https://use.typekit.net/gqd2acu.css'/>
              <HistoryRouter history={ history }>
                <Routes>
                  <Route path='/auth/*' element={ <AuthRoutes /> } />
                  <Route
                    path='/*'
                    element={
                      <AuthGuard>
                        <AppRoutes />
                      </AuthGuard>
                    }
                  />
                </Routes>
              </HistoryRouter>
            </SnackbarProvider>
          </Suspense>
        </UserContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
