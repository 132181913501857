import React, { useContext, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import UserContext from 'context/user.context';
import { User } from 'data/interfaces/user';
import { UserService } from 'services/user.service';
import { CompanyService } from 'services/companies.service';
import useHandleErrors from 'hooks/useHandleErrors';
import { Company } from 'data/interfaces/company';

const AuthGuard = ({ children }: React.PropsWithChildren<{}>) => {
  const navigate = useNavigate();
  const { setUser, setCompany } = useContext(UserContext);
  const [ isAuthenticated, setAuthenticated ] = useState<boolean>(false);
  const location = useLocation();
  const { handleErrors } = useHandleErrors();

  useEffect(() => {
    UserService.get()
      .then(({ data }: AxiosResponse<User>) => {
        setUser(data);
        setAuthenticated(true);
        if(data.selectedCompany?.id)
          CompanyService.single(data.selectedCompany.id)
            .then((companyResponse: AxiosResponse<Company>) => {
              setCompany(companyResponse.data);
            })
            .catch(e => handleErrors(e));
        else
          setCompany(null);
      })
      .catch(() => {
        const currentPath = location.pathname;

        if (currentPath === '/') {
          navigate('/auth/login');
        } else {
          navigate(`/auth/login?next=${ currentPath }`);
        }
      });
  }, []);

  // eslint-disable-next-line no-undef
  return isAuthenticated ? (children as JSX.Element) : null;
};

export default AuthGuard;
