import endpoints from 'data/endpoints';
import { post } from 'lib/axios';

type LoginRequest = {
  email: string;
  password: string;
};

type RegisterRequest = {
  email: string;
  firstName?: string;
  lastName?: string;
  password1: string;
  password2: string;
};

type ChangePasswordRequest = {
  oldPassword: string;
  newPassword1: string;
  newPassword2: string;
};

type VerifyRequest = {
  token: string;
};

type ResetPassRequest = {
  email: string;
};
type ResetPassConfirmRequest = {
  password: string;
  token: string;
};

export const AuthService = {
  login: (data: LoginRequest) => post(endpoints.auth.login, data),
  register: (data: RegisterRequest) => post(endpoints.auth.register, data),
  resetPassword: (data: ResetPassRequest) => post(endpoints.auth.resetPassword, data),
  resetPasswordConfirm: (data: ResetPassConfirmRequest) =>
    post(endpoints.auth.resetPasswordConfirm, data),
  logout: () => post(endpoints.auth.logout, {}),
  verifyToken: () => post(endpoints.auth.verifyToken, {}),
  validateResetToken: (data: VerifyRequest) => post(endpoints.auth.validateResetToken, data),
  refreshToken: () => post(endpoints.auth.refreshToken, {}),
  verifyEmail: (data: VerifyRequest) => post(endpoints.auth.verifyEmail, data),
  changePassword: (data: ChangePasswordRequest) => post(endpoints.auth.changePassword, data),
};
