import axios from 'axios';
import endpoints, { apiUrl } from 'data/endpoints';
import { AuthService } from 'services/auth.service';

const http = axios.create({
  baseURL: apiUrl,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

http.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;
    if (
      originalConfig.url !== endpoints.auth.login && err.response?.status === 401 &&
     !originalConfig._retry
    ) {
      originalConfig._retry = true;
      return AuthService.refreshToken()
        .then(() => http(originalConfig))
        .catch(e => Promise.reject(e));
    } else
      return Promise.reject(err);
  }
);

const { get, post, put, patch, delete: destroy } = http;
export { get, post, put, patch, destroy };
