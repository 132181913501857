import { UserRequestBody } from 'data/interfaces/user';
import endpoints from '../data/endpoints';
import { get, patch } from '../lib/axios';

export const UserService = {
  get: () => get(endpoints.user.index),
  update: (data: UserRequestBody) => patch(
    endpoints.user.index, data,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  ),
  setCompany: (companyId?: number) => patch(endpoints.user.index, {
    selectedCompanyId: companyId
  }),
};
