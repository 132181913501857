/* eslint-disable no-undef */
const endpoints = {
  auth: {
    register: '/auth/register/',
    login: '/auth/login/',
    logout: '/auth/logout/',
    refreshToken: '/auth/token/refresh/',
    verifyToken: '/auth/token/verify/',
    validateResetToken: '/auth/reset-password/validate_token/',
    verifyEmail: '/auth/register/verify-email/',
    resetPassword: '/auth/reset-password/',
    resetPasswordConfirm: '/auth/reset-password/confirm/',
    changePassword: '/auth/change-password/',
  },
  user: {
    index: '/users/me/',
  },
  companies: {
    index: '/companies/',
    single: (id: number) => `/companies/${ id }/`,
    syndications: '/syndications/',
  },
  listings: {
    rentals: {
      index: '/properties/rentals/',
      single: (id: number) => `/properties/rentals/${ id }/`,
      duplicate: (id: number, includeFloorplans?: boolean) =>
        `/properties/rentals/${ id }/duplicate/${ includeFloorplans ? '?floorplans=true' : '' }`,
      undoDelete: (id: number) => `/properties/rentals/${ id }/undo-delete/`,
      setLogo: (id: number) => `/properties/rentals/${ id }/set-logo/`,
      unsetLogo: (id: number) => `/properties/rentals/${ id }/unset-logo/`,
      addImages: (id: number) => `/properties/rentals/${ id }/images/`,
      propertyTypes: '/properties/rental-property-types/',
      amnieties: '/properties/rental-amenities/',
      floorplans: {
        index: '/properties/rentalfloorplans/',
        single: (id: number) => `/properties/rentalfloorplans/${ id }/`,
        duplicate: (id: number) => `/properties/rentalfloorplans/${ id }/duplicate/`,
        undoDelete: (id: number) => `/properties/rentalfloorplans/${ id }/undo-delete/`,
        addImages: (id: number) => `/properties/rentalfloorplans/${ id }/images/`,
        amnieties: '/properties/rental-floorplan-amenities/',
      },
    },
    constructions: {
      index: '/properties/constructions/',
      single: (id: number) => `/properties/constructions/${ id }/`,
      duplicate: (id: number) => `/properties/constructions/${ id }/duplicate/`,
      undoDelete: (id: number) => `/properties/constructions/${ id }/undo-delete/`,
      setLogo: (id: number) => `/properties/constructions/${ id }/set-logo/`,
      unsetLogo: (id: number) => `/properties/constructions/${ id }/unset-logo/`,
      addImages: (id: number) => `/properties/constructions/${ id }/images/`,
      amnieties: '/properties/construction-amenities/',
      propertyTypes: '/properties/construction-property-types/',
      stages: '/properties/construction-stages/',
      floorplans: {
        index: '/properties/constructionfloorplans/',
        single: (id: number) => `/properties/constructionfloorplans/${ id }/`,
        duplicate: (id: number) => `/properties/constructionfloorplans/${ id }/duplicate/`,
        undoDelete: (id: number) => `/properties/constructionfloorplans/${ id }/undo-delete/`,
        addImages: (id: number) => `/properties/constructionfloorplans/${ id }/images/`,
        addPdf: (id: number) => `/properties/constructionfloorplans/${ id }/add-pdf/`,
        removePdf: (id: number, pdfId: number) =>
          `/properties/constructionfloorplans/${ id }/remove-pdf/${ pdfId }/`,
        amnieties: '/properties/construction-floorplan-amenities/',
      },
    },
    utilities: '/properties/utilities/',
    propertyStages: '/properties/property-stages/',
    salesRepresentatives: '/properties/sales-representatives/',
  },
  leads: {
    index: '/users/leads/',
    exportCSV: '/users/leads/export-csv',
  },
  neighborhoods: {
    provinces: '/neighborhoods/provinces/',
  },
};

const localBackendUrl = 'http://localhost:8000';
export const backendUrl =
  process.env.NODE_ENV !== 'production' ? localBackendUrl : process.env.REACT_APP_API_URL;
export const apiUrl = `${ backendUrl }/api`;

export default endpoints;
